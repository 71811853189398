<template>
  <div class="dash-pro-item mb-30 dashboard-widget">
    <div class="header">
      <h4 class="title">Selecione a data de retirada</h4>
    </div>

    <div class="row center">
      <div class="col-12 col-lg-6 col-md-6 text-center mb-3">
        <CardLote />
      </div>
      <div class="col-12 col-lg-6 col-md-6 text-center">
        <v-date-picker mode="date" v-model="data" is24hr timezone="utc" :available-dates="datas" :first-day-of-week="1" />
        <div>
          <select v-model="hora">
            <option :value="''">Selecione a hora</option>
            <option v-for="(h, index) in horarios" :key="index" :value="h">
              {{ h }}
            </option>
          </select>
        </div>
      </div>
    </div>
    <div class="col-12 text-center mt-3">
      <Button tela="Lotes" desc="voltar" :width="45" class="mr-2" />
      <Button :click="proximo" desc="próximo" :width="45" :disabled="disabledBtn" />
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import Button from './components/AgButton.vue';
import CardLote from './components/CardLote.vue';
import moment from 'moment';
export default {
  components: {
    Button,
    CardLote,
  },
  data: () => ({
    data: null,
    agendamentos: [],
    datas: [],
    horarios: [],
    hora: '',
  }),
  computed: {
    ...mapGetters(['getAgLote', 'getAgLoteId', 'getAgData', 'getAgHora']),
    disabledBtn() {
      return this.getAgData && this.getAgHora ? false : true;
    },
  },
  watch: {
    data(val) {
      if (!val) {
        this.setAgData();
        this.hora = '';
        this.horarios = [];
        return;
      }
      this.setAgData(val);

      console.log(moment(this.getAgData).utc());

      this.hora = '';
      if (val) {
        this.filtraHoras(val);
      }
    },
    hora(val) {
      this.setAgHora(val);
    },
  },
  methods: {
    ...mapMutations(['setAgAgendar', 'setAgData', 'setAgHora', 'setAgTela', 'setAgAgendamentoId']),
    ...mapActions(['agendaDisponivel']),
    async configuracao() {
      const agendamentos = await this.agendaDisponivel(this.getAgLote.loteId);

      this.agendamentos = agendamentos.data.data;
      this.datas = agendamentos.data.data.map((e) => {
        return {
          start: moment(e.data).toDate(),
          end: moment(e.data).toDate(),
        };
      });
      console.log(this.datas);
      return;
    },
    proximo() {
      if (!this.getAgData || !this.getAgHora) return;
      this.setAgTela('Resumo');
    },
    filtraHoras(data) {
      this.horarios = this.agendamentos.find((e) => moment(e.data).utc().format('YYYY-MM-DD') == moment(data).utc().format('YYYY-MM-DD'))?.horas;
    },
  },
  async created() {
    await this.configuracao();
    this.setAgData();
    this.setAgHora();
    this.setAgAgendamentoId();
    // this.filtraHoras(new Date());
  },
};
</script>

<style scoped>
select {
  width: 250px;
  border-radius: 10px;
}
</style>
